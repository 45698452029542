import React from "react";

const Hero = () => {
  return (
    <div className="grid  grid-cols-1  md:grid-cols-2 gap-8 mt-8 md:mt-20" id="hero">
      <div className=" grid col-span-1 md:col-span-2 self-center	 place-content-start	gap-8">
        <h2 className=" text-4xl md:text-7xl   leading-tight tracking-wide	">
          Let the{" "}
          <span className="text-[#F9CD51] underline underline-offset-8">
            Music
          </span>{" "}
          Speak to you
        </h2>

        <p className="text-slate-500 font-medium text-lg md:text-2xl tracking-wide	">
          Experience the ultimate musical journey with our expertly crafted
          playlists
        </p>
      </div>

      <div className="col-span-1 md:col-span-2 lg:col-end-7">
        <div className="relative overflow-hidden rounded-b-full border-2 border-[#F9CD51]">
          <img
          loading="lazy"
            src="/assets/heroImage.jpeg"
            alt="flavour and ceo"
            className="object-cover w-full h-full"
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;

// Footer.js
import { FaFacebook, FaInstagram } from "react-icons/fa6";

const Footer = () => {
  return (
    <footer className="bg-black text-white py-4" id="contact">
      <div className="container mx-auto flex flex-col items-center">
        <img src="/assets/sisqo_logo_2.jpeg" alt="Logo" className="h-16 mb-4" />
        <div className="text-2xl text-[#F9CD51] flex gap-4 my-2">
       
       <a href="https://web.facebook.com/Sisqoproductions/?_rdc=1&_rdr">
         <FaFacebook />
       </a>
       <a href="https://www.instagram.com/sisqochris/?hl=en">
         <FaInstagram />
       </a>
     </div>
        <div className="flex  gap-5 my-3  text-[#F9CD51]">
            <p> +27100011301</p>
            <p>+27871483301</p>
            <p>+27787544436</p>
          </div>
    
        <address className="not-italic mb-2 text-center text-[#F9CD51]">
          Address 49 Jorrison street Braamfontein Johannesburg 2001
        </address>
        <a href="mailto:Sisqopro@gmail.com" className="text-[#F9CD51] ">
          Sisqopro@gmail.com
        </a>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import NavItem from "./NavItem";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import SideBar from "../SideBar/SideBar";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="sticky top-0 grid w-full lg:grid-cols-5 gap-4 px-8 bg-white  md:grid z-[1] border-b-2">
      <a href="/">
      <img src="/assets/Sisqo_Logo_1.png" alt="" width="180" />
      </a>

        <div className=" col-span-5 col-start-2 hidden items-center justify-between text-xl lg:flex">
          <div>
            <NavItem to="/" route="Home" />
          </div>

          <div>
            <NavItem to="#about" route="What we do" />
          </div>

          <div>
            <NavItem to="#pricing" route="Pricing" />
          </div>

          <div>
            <NavItem to="#events" route="Events" />
          </div>

          <div>
            <NavItem to="/gallery" route="Gallery" />
          </div>
          <div>
            <NavItem to="#contact" route="Contact Us" />
          </div>
        </div>
        <div className="grid lg:hidden col-end-7 col-span-2 lg:col-span-1 justify-center items-center">
          <button
            className="rounded-full bg-black h-[40px] w-[40px] flex justify-center items-center"
            onClick={toggleSidebar}
          >
            {isOpen ? (
              <AiOutlineClose className="text-white text-xl" />
            ) : (
              <GiHamburgerMenu className="text-white text-xl" />
            )}
          </button>
        </div>
      </div>
      <SideBar isOpen={isOpen} />
    </>
  );
};

export default Navbar;

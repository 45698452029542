import React from 'react'
import { ImQuotesLeft } from "react-icons/im";

const CEO = () => {
  return (
    <div className='flex  gap-2 lg:gap-20  my-20 flex-col-reverse lg:flex-row'>

<div className='flex justify-center  lg:justify-start'>
<div className='rounded-full w-52 h-52 border '>
<img src="/assets/CEO.jpeg" alt="The ceo"  className='rounded-full w-52 h-52 object-cover'  />
</div>


</div>

<div>
<h4 className='text-3xl  '>Meet the CEO</h4>
<p className='font-semibold italic my-5'>
<span><ImQuotesLeft /></span>
Our passion for music drives everything we do and inspires us to create exceptional soundtracks every day.</p>
</div>


    </div>
  )
}

export default CEO
import React from 'react'

const Partners = () => {
  return (
    <div className=' flex justify-center items-center  flex-col md:flex-row'>
        <div>
        <img src="/assets/sisqo_logo_2.jpeg" alt="" width='200' />

        </div>
<div>
<img src="/assets/logo.jpeg" alt=""  width='220' />

</div>
    </div>
  )
}

export default Partners
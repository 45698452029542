import { ImageArray } from "../types/pricing";
export const imagesArray:ImageArray[] = [
    {
      alt: "Image1's alt text",
      caption: "Image1's description",
      src: "/assets/Gallery/1.jpeg",
    },
    {
      alt: "Image2's alt text",
      caption: "Image2's description",
      src: "/assets/Gallery/2.jpeg",
    },
    {
      alt: "Image3's alt text",
      caption: "Image3's description",
      src: "/assets/Gallery/3.jpeg",
    },
    {
      alt: "Image4's alt text",
      caption: "Image4's description",
      src: "/assets/Gallery/4.jpeg",
    },
    {
      alt: "Image5's alt text",
      caption: "Image5's description",
      src: "/assets/Gallery/5.jpeg",
    },
    {
      alt: "Image6's alt text",
      caption: "Image6's description",
      src: "/assets/Gallery/6.jpeg",
    },
    {
      alt: "Image7's alt text",
      caption: "Image7's description",
      src: "/assets/Gallery/7.jpeg",
    },
    {
      alt: "Image8's alt text",
      caption: "Image8's description",
      src: "/assets/Gallery/8.jpeg",
    },
    {
      alt: "Image9's alt text",
      caption: "Image9's description",
      src: "/assets/Gallery/9.jpeg",
    },
    {
      alt: "Image10's alt text",
      caption: "Image10's description",
      src: "/assets/Gallery/10.jpeg",
    },
    {
      alt: "Image11's alt text",
      caption: "Image11's description",
      src: "/assets/Gallery/11.jpeg",
    },
    {
      alt: "Image12's alt text",
      caption: "Image12's description",
      src: "/assets/Gallery/12.jpeg",
    },
    {
      alt: "Image13's alt text",
      caption: "Image13's description",
      src: "/assets/Gallery/13.jpeg",
    },
    {
      alt: "Image14's alt text",
      caption: "Image14's description",
      src: "/assets/Gallery/14.jpeg",
    },
    {
      alt: "Image15's alt text",
      caption: "Image15's description",
      src: "/assets/Gallery/15.jpeg",
    },
    {
      alt: "Image16's alt text",
      caption: "Image16's description",
      src: "/assets/Gallery/16.jpeg",
    },
    {
      alt: "Image17's alt text",
      caption: "Image17's description",
      src: "/assets/Gallery/17.jpeg",
    },
    {
      alt: "Image18's alt text",
      caption: "Image18's description",
      src: "/assets/Gallery/18.jpeg",
    },
    {
      alt: "Image19's alt text",
      caption: "Image19's description",
      src: "/assets/Gallery/19.jpeg",
    },
    {
      alt: "Image20's alt text",
      caption: "Image20's description",
      src: "/assets/Gallery/20.jpeg",
    },
    {
      alt: "Image21's alt text",
      caption: "Image21's description",
      src: "/assets/Gallery/21.jpeg",
    },
    {
      alt: "Image22's alt text",
      caption: "Image22's description",
      src: "/assets/Gallery/22.jpeg",
    },
    {
      alt: "Image23's alt text",
      caption: "Image23's description",
      src: "/assets/Gallery/23.jpeg",
    },
    {
      alt: "Image24's alt text",
      caption: "Image24's description",
      src: "/assets/Gallery/24.jpeg",
    },
    {
      alt: "Image25's alt text",
      caption: "Image25's description",
      src: "/assets/Gallery/25.jpeg",
    },
    {
      alt: "Image26's alt text",
      caption: "Image26's description",
      src: "/assets/Gallery/26.jpeg",
    },
    {
      alt: "Image27's alt text",
      caption: "Image27's description",
      src: "/assets/Gallery/27.jpeg",
    },
    {
      alt: "Image28's alt text",
      caption: "Image28's description",
      src: "/assets/Gallery/28.jpeg",
    },
    {
      alt: "Image29's alt text",
      caption: "Image29's description",
      src: "/assets/Gallery/29.jpeg",
    },
    {
      alt: "Image30's alt text",
      caption: "Image30's description",
      src: "/assets/Gallery/30.jpeg",
    },
    {
      alt: "Image31's alt text",
      caption: "Image31's description",
      src: "/assets/Gallery/31.jpeg",
    },
    {
      alt: "Image32's alt text",
      caption: "Image32's description",
      src: "/assets/Gallery/32.jpeg",
    },
    {
      alt: "Image33's alt text",
      caption: "Image33's description",
      src: "/assets/Gallery/33.jpeg",
    },
    {
      alt: "Image34's alt text",
      caption: "Image34's description",
      src: "/assets/Gallery/34.jpeg",
    },
    {
      alt: "Image35's alt text",
      caption: "Image35's description",
      src: "/assets/Gallery/35.jpeg",
    },
    {
      alt: "Image36's alt text",
      caption: "Image36's description",
      src: "/assets/Gallery/36.jpeg",
    },
    {
      alt: "Image37's alt text",
      caption: "Image37's description",
      src: "/assets/Gallery/37.jpeg",
    },
    {
      alt: "Image38's alt text",
      caption: "Image38's description",
      src: "/assets/Gallery/38.jpeg",
    },
    {
      alt: "Image39's alt text",
      caption: "Image39's description",
      src: "/assets/Gallery/39.jpeg",
    },
    {
      alt: "Image40's alt text",
      caption: "Image40's description",
      src: "/assets/Gallery/40.jpeg",
    },
    {
      alt: "Image41's alt text",
      caption: "Image41's description",
      src: "/assets/Gallery/41.jpeg",
    },
    {
      alt: "Image42's alt text",
      caption: "Image42's description",
      src: "/assets/Gallery/42.jpeg",
    },
    {
      alt: "Image43's alt text",
      caption: "Image43's description",
      src: "/assets/Gallery/43.jpeg",
    },
    {
      alt: "Image44's alt text",
      caption: "Image44's description",
      src: "/assets/Gallery/44.jpeg",
    },
    {
      alt: "Image45's alt text",
      caption: "Image45's description",
      src: "/assets/Gallery/45.jpeg",
    },
    {
      alt: "Image46's alt text",
      caption: "Image46's description",
      src: "/assets/Gallery/46.jpeg",
    },
    {
      alt: "Image47's alt text",
      caption: "Image47's description",
      src: "/assets/Gallery/47.jpeg",
    },
    {
      alt: "Image48's alt text",
      caption: "Image48's description",
      src: "/assets/Gallery/48.jpeg",
    },
    {
      alt: "Image49's alt text",
      caption: "Image49's description",
      src: "/assets/Gallery/49.jpeg",
    },
    {
      alt: "Image50's alt text",
      caption: "Image50's description",
      src: "/assets/Gallery/50.jpeg",
    },
    {
      alt: "Image51's alt text",
      caption: "Image51's description",
      src: "/assets/Gallery/51.jpeg",
    },
    {
      alt: "Image52's alt text",
      caption: "Image52's description",
      src: "/assets/Gallery/52.jpeg",
    },
    {
      alt: "Image53's alt text",
      caption: "Image53's description",
      src: "/assets/Gallery/53.jpeg",
    },
    {
      alt: "Image54's alt text",
      caption: "Image54's description",
      src: "/assets/Gallery/54.jpeg",
    },
    {
      alt: "Image55's alt text",
      caption: "Image55's description",
      src: "/assets/Gallery/55.jpeg",
    },
    {
      alt: "Image56's alt text",
      caption: "Image56's description",
      src: "/assets/Gallery/56.jpeg",
    },
    {
      alt: "Image57's alt text",
      caption: "Image57's description",
      src: "/assets/Gallery/57.jpeg",
    },
    {
      alt: "Image58's alt text",
      caption: "Image58's description",
      src: "/assets/Gallery/58.jpeg",
    },
    {
      alt: "Image59's alt text",
      caption: "Image59's description",
      src: "/assets/Gallery/59.jpeg",
    },
    {
      alt: "Image60's alt text",
      caption: "Image60's description",
      src: "/assets/Gallery/60.jpeg",
    },
    {
      alt: "Image61's alt text",
      caption: "Image61's description",
      src: "/assets/Gallery/61.jpeg",
    },
    {
      alt: "Image62's alt text",
      caption: "Image62's description",
      src: "/assets/Gallery/62.jpeg",
    },
    {
      alt: "Image63's alt text",
      caption: "Image63's description",
      src: "/assets/Gallery/63.jpeg",
    },
    {
      alt: "Image64's alt text",
      caption: "Image64's description",
      src: "/assets/Gallery/64.jpeg",
    },
    {
      alt: "Image65's alt text",
      caption: "Image65's description",
      src: "/assets/Gallery/65.jpeg",
    },
    {
      alt: "Image66's alt text",
      caption: "Image66's description",
      src: "/assets/Gallery/66.jpeg",
    },
    {
      alt: "Image67's alt text",
      caption: "Image67's description",
      src: "/assets/Gallery/67.jpeg",
    },
    {
      alt: "Image68's alt text",
      caption: "Image68's description",
      src: "/assets/Gallery/68.jpeg",
    },
    {
      alt: "Image69's alt text",
      caption: "Image69's description",
      src: "/assets/Gallery/69.jpeg",
    },
    {
      alt: "Image70's alt text",
      caption: "Image70's description",
      src: "/assets/Gallery/70.jpeg",
    },
    {
      alt: "Image71's alt text",
      caption: "Image71's description",
      src: "/assets/Gallery/71.jpeg",
    },
    {
      alt: "Image72's alt text",
      caption: "Image72's description",
      src: "/assets/Gallery/72.jpeg",
    },
    {
      alt: "Image73's alt text",
      caption: "Image73's description",
      src: "/assets/Gallery/73.jpeg",
    },
    {
      alt: "Image74's alt text",
      caption: "Image74's description",
      src: "/assets/Gallery/74.jpeg",
    },
    {
      alt: "Image75's alt text",
      caption: "Image75's description",
      src: "/assets/Gallery/75.jpeg",
    },
    {
      alt: "Image76's alt text",
      caption: "Image76's description",
      src: "/assets/Gallery/76.jpeg",
    },
    {
      alt: "Image77's alt text",
      caption: "Image77's description",
      src: "/assets/Gallery/77.jpeg",
    },
    {
      alt: "Image78's alt text",
      caption: "Image78's description",
      src: "/assets/Gallery/78.jpeg",
    },
    {
      alt: "Image79's alt text",
      caption: "Image79's description",
      src: "/assets/Gallery/79.jpeg",
    },
    {
      alt: "Image80's alt text",
      caption: "Image80's description",
      src: "/assets/Gallery/80.jpeg",
    },
    {
      alt: "Image81's alt text",
      caption: "Image81's description",
      src: "/assets/Gallery/81.jpeg",
    },
    {
      alt: "Image82's alt text",
      caption: "Image82's description",
      src: "/assets/Gallery/82.jpeg",
    },
    {
      alt: "Image83's alt text",
      caption: "Image83's description",
      src: "/assets/Gallery/83.jpeg",
    },
    {
      alt: "Image84's alt text",
      caption: "Image84's description",
      src: "/assets/Gallery/84.jpeg",
    },
    {
      alt: "Image85's alt text",
      caption: "Image85's description",
      src: "/assets/Gallery/85.jpeg",
    },
    {
      alt: "Image86's alt text",
      caption: "Image86's description",
      src: "/assets/Gallery/86.jpeg",
    },
    {
      alt: "Image87's alt text",
      caption: "Image87's description",
      src: "/assets/Gallery/87.jpeg",
    },
    {
      alt: "Image88's alt text",
      caption: "Image88's description",
      src: "/assets/Gallery/88.jpeg",
    },
    {
      alt: "Image89's alt text",
      caption: "Image89's description",
      src: "/assets/Gallery/89.jpeg",
    },
    {
      alt: "Image90's alt text",
      caption: "Image90's description",
      src: "/assets/Gallery/90.jpeg",
    },
    {
        alt: "Image91's alt text",
        caption: "Image91's description",
        src: "/assets/Gallery/91.jpeg",
      },
      {
        alt: "Image92's alt text",
        caption: "Image92's description",
        src: "/assets/Gallery/92.jpeg",
      },
      {
        alt: "Image93's alt text",
        caption: "Image93's description",
        src: "/assets/Gallery/93.jpeg",
      },
      {
        alt: "Image94's alt text",
        caption: "Image94's description",
        src: "/assets/Gallery/94.jpeg",
      },
      {
        alt: "Image95's alt text",
        caption: "Image95's description",
        src: "/assets/Gallery/95.jpeg",
      },
      {
        alt: "Image96's alt text",
        caption: "Image96's description",
        src: "/assets/Gallery/96.jpeg",
      },
      {
        alt: "Image97's alt text",
        caption: "Image97's description",
        src: "/assets/Gallery/97.jpeg",
      },
      {
        alt: "Image98's alt text",
        caption: "Image98's description",
        src: "/assets/Gallery/98.jpeg",
      },
      {
        alt: "Image99's alt text",
        caption: "Image99's description",
        src: "/assets/Gallery/99.jpeg",
      },
      {
        alt: "Image100's alt text",
        caption: "Image100's description",
        src: "/assets/Gallery/100.jpeg",
      },
      {
        alt: "Image101's alt text",
        caption: "Image101's description",
        src: "/assets/Gallery/101.jpeg",
      },
      {
        alt: "Image102's alt text",
        caption: "Image102's description",
        src: "/assets/Gallery/102.jpeg",
      },
      {
        alt: "Image103's alt text",
        caption: "Image103's description",
        src: "/assets/Gallery/103.jpeg",
      },
      {
        alt: "Image104's alt text",
        caption: "Image104's description",
        src: "/assets/Gallery/104.jpeg",
      }
  ];
  
import React from "react";
import Hero from "../Components/Hero/Hero";
import About from "../Components/About/About";
import Partners from "../Components/Partners/Partners";
import Pricing from "../Components/Pricing/Pricing";
import Events from "../Components/Events/Events";
import CEO from "../Components/CEO/CEO";
import Footer from "../Components/Footer/Footer";

const Home = () => {
  return (
    <>
      <div className=" px-10 md:px-20">
        <Hero />
        <About />
        <Partners />
        <Pricing />
        <Events />
        <CEO />
      </div>
      <Footer />
    </>
  );
};

export default Home;

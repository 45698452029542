import React from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import Measure from "react-measure";
import { imagesArray } from "../Utils/Utils";



const Galllery = () => {
  const items = imagesArray.map((_, index) => (
    <Measure key={index}>
      {({ measureRef }: any) => (
        <div ref={measureRef} className="mx-1 my-1 lg:mx-2 lg:my-4">
          <img
            key={index}
            src={_.src}
            loading="lazy"
            alt={_.alt}
            style={{ width: "100%", borderRadius: "8px"  }}
          />
        </div>
      )}
    </Measure>
  ));
  return (
    <div className=" px-10 md:px-20">
      <ResponsiveMasonry
        columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4, 900: 5 }}
      >
        <Masonry>{items}</Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Galllery;

import React from "react";

const Events = () => {
  const images = [
    {
      image: "/assets/Events_1.jpeg",
      label: "Events_1",
    },
    {
      image: "/assets/Events_2.jpeg",
      label: "Events_2",
    },
    {
      image: "/assets/Events_3.jpeg",
      label: "Events_3",
    },
    {
      image: "/assets/Events_4.jpeg",
      label: "Events_4",
    },
    {
      image: "/assets/Events_5.jpeg",
      label: "Events_5",
    },
    {
      image: "/assets/Events_6.jpeg",
      label: "Events_6",
    },
    {
      image: "/assets/Events_7.jpeg",
      label: "Events_7",
    },
    {
      image: "/assets/Events_8.jpeg",
      label: "Events_8",
    },
    {
      image: "/assets/Events_9.jpeg",
      label: "Events_9",
    },
    {
      image: "/assets/Events_10.jpeg",
      label: "Events_10",
    },
  ];

  return (
    <div id="events" >
      <div className="text-center  w-full">
        <h3 className=" text-lg lg:text-3xl mb-6">Our Events</h3>
      </div>

      <div className="grid grid-cols-1  md:grid-cols-3 lg:grid-cols-5 gap-3">
        {images.map((item, index) => (
          <img
            src={item.image}
            alt={item.label}
            key={index}
            className="w-full object-cover"
            loading="lazy"
          />
        ))}
      </div>
    </div>
  );
};

export default Events;

import React from "react";
import Navbar from "./Components/Navbar/Navbar";
import { Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Components/About/About";
import Product from "./Components/Product/Product";
import Pricing from "./Components/Pricing/Pricing";
import Galllery from "./Pages/Galllery";

function App() {
  return (
    <div>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />

           <Route path="/" element={<About />} />
        <Route path="/" element={<Product />} />
        <Route path="/" element={<Pricing />} />
        <Route path="/gallery" element={<Galllery/>} />


      </Routes>
    </div>
  );
}

export default App;

import NavItem from "../Navbar/NavItem";

interface SideBarProps {
  isOpen: Boolean;
}

const Sidebar = ({ isOpen }: SideBarProps) => {
  return (
    <div className="flex">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-50 w-64 bg-white  transition-transform duration-300 ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        {/* Sidebar content */}
        <div className="p-4">
          <div>
            <img src="/assets/sisqo_logo_2.jpeg" alt="logo" width="80" />
          </div>
          {/* Add your sidebar links or content here */}

          <div>
            <div className="my-8 w-full  ">
              <NavItem to="/" route="Home" />
            </div>
            <div className="my-8">
              <NavItem to="#about" route="What we do" />
            </div>

            <div className="my-8">
              {" "}
              <NavItem to="#pricing" route="Pricing" />
            </div>
            <div className="my-8">
              <NavItem to="#events" route="Events" />
            </div>

            <div className="my-8">
              {" "}
              <NavItem to="/gallery" route="Gallery" />
            </div>
            <div className="my-8">
              {" "}
              <NavItem to="#contact" route="Contact Us" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

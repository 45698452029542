interface NavItemProp {
    to: string;
    route: string;
  }
  
  const NavItem = ({ to, route }: NavItemProp) => {
    return (
      <a href={to} style={{ width: "100%" }} className="active-link text-lg font-medium">
        {route}
      </a>
    );
  };
  
  export default NavItem;
  
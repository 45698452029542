import { PricingTypes } from "../../types/pricing";

 export const  mixingPriceData:PricingTypes = [
    {
        id: "1",
        feature:"1 to 24 stems/tracks  ",
        price: 1600
    },
    {
        id: "2",
        feature:"25 to 48 stems/tracks  ",
        price: 1850
    },
    {
        id: "3",
        feature:"49 to 72 stems/tracks  ",
        price: 2100
    },
   
 ]



 export const  masteringPriceData:PricingTypes = [
    {
        id: "1",
        feature:"Standard Mastering",
        price: 599
    },
    {
        id: "2",
        feature:"Apple Digital Master ",
        price: 199
    },
    {
        id: "3",
        feature:"49 to 72 stems/tracks  ",
        price: 2100
    },
   
 ]



 export const  studioRecordingPriceData:PricingTypes = [
    {
        id: "1",
        feature:"3 hours ",
        price: 900
    },
    {
        id: "2",
        feature:"6 hours ",
        price: 1250
    },
    {
        id: "3",
        feature:"12 hours",
        price: 1800
    },
   
 ]
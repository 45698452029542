import { IoMusicalNotesSharp } from "react-icons/io5";
import { ImStack } from "react-icons/im";
import { FaVideoSlash } from "react-icons/fa6";
import ProductCard from "./ProductCard";
const Product = () => {
  return (
    <div className=" my-[2rem]  lg:my-[8rem]" id="product">
      <div className=" my-[2rem]  ">
        <ProductCard
          imageOnRight={true}
          title="Crafting Your Sound, Capturing Your Vision."
          subTitile="At Sisqo Production, we believe in the power of music and video to tell stories, evoke emotions, and create lasting memories. We offer a wide range of music production services to cater to artists, bands, and creators from all genres and backgrounds. Whether you're an aspiring musician or an established artist, our team of experienced producers and engineers is here to bring your vision to life."
          image="/assets/About_3.jpeg"
          icon={<IoMusicalNotesSharp />}
          iconColor="bg-[#F9CD51]"
          backGround="bg-[#000]"
        />
        <ProductCard
          imageOnRight={false}
          title="Sisqo Production: Where Every Beat Tells a Story."
          subTitile="Welcome to Sisqo Production, where we transform melodies into memories and visions into masterpieces. As a premier destination for music and video production, we pride ourselves on our commitment to excellence and our dedication to bringing your artistic dreams to fruition."
          image="/assets/About_1.jpeg"
          icon={<ImStack />}
          iconColor="bg-[#F9CD51]"
          backGround="bg-[#000]"
          imageNumber2={2}
        />
        <ProductCard
          imageOnRight={true}
          title="Elevating Your Artistry, Amplifying Your Impact."
          subTitile="As a full-service music and video production powerhouse, Sisqo Production offers a dynamic array of services designed to meet the diverse needs of today's creators. From studio recording and live performance videos to cinematic storytelling and immersive visual experiences, our team of industry experts is equipped with the tools and expertise to bring your artistic vision to life."
          image="/assets/About_2.jpeg"
          icon={<FaVideoSlash />}
          iconColor="bg-[#F9CD51]"
          backGround="bg-[#000]"
          imageNumber2={3}
        />
      </div>
    </div>
  );
};

export default Product;

import {motion} from "framer-motion"
import { PricingTypes } from "../../types/pricing";
interface PricingCardProps {
  subscriptionType: string;
  amount: string;
  title: string;
  subTitle: string;
  backGround: string;
  pricingArray: PricingTypes
}

const PricingCard = ({
  amount,
  subTitle,
  subscriptionType,
  title,
  backGround,
  pricingArray
}: PricingCardProps) => {
  const featuresItem = pricingArray.map((feature) => (
    <li className="list-disc my-5 flex  justify-between" key={feature.id}>{feature.feature}  &nbsp; &nbsp; &nbsp; &nbsp;  <h4 className="text-lg font-semibold">R{feature.price}</h4></li>
  ));

  return (
    <motion.div className="border-2 border-[#F9CD51] p-5  col-span-3 rounded-2xl  my-8  md:my-3" whileHover={{scale:1.06}} transition={{type:"spring"}}>
      <div className="flex justify-center">
        <h3 className="text-2xl">{subscriptionType}</h3>
      </div>
      <div className="flex justify-center my-5">
        <p>For individuals and teams</p>
      </div>
      {/* <div className={`flex p-4 justify-center gap-6 ${backGround} my-[2rem]`}>
        <div className="flex items-end">
          <h3 className="text-2xl">${amount}</h3>
        </div>
        <div>
          <h4 className="text-lg">{title}</h4>
          <p className="text-sm">{subTitle}</p>
        </div>
      </div> */}

      <div className="my-[2rem]">
        <ul className="px-8 ">{featuresItem} </ul>
      </div>

      <div className="flex  items-center justify-center  ">
        <button className=" border-2 border-[#F9CD51] w-full py-3 rounded-md">
         Get Started
        </button>
      </div>

      <div className="py-6 flex justify-center ">
        <p>For more enquiries, <span className="underline underline-offset-4 text-[#F9CD51] font-semibold text-lg"><a href="mailto:Sisqopro@gmail.com">contact us</a></span></p>
      </div>
    </motion.div>
  );
};

export default PricingCard;

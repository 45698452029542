import React from "react";
import Product from "../Product/Product";

const About = () => {
  return (
    <div className="rounded-lg  my-8" id="about">
      <div className="w-full text-center my-8">
        <h3 className="text-2xl md:4xl text-[#F9CD51] underline underline-offset-8">
          What we do
        </h3>
      </div>

      <div className="tracking-wide">
        <p className="text-black text-center md:text-2xl leading-normal tracking-wide">
        Sisqo Productions is a premier production house specializing in music production, music video production, and movie production. We collaborate with talented and ambitious artists from various African countries.

        Established in 2011 in Johannesburg, South Africa, Sisqo Productions has grown significantly. We have expanded into the Nigerian market, working with several A-list artists to create exceptional content.
        Our journey began as a music production studio, a passion that remains at the core of our business. Over time, we expanded into music video production, and eventually, we ventured into movie production.
        </p>
      </div>

      <Product />
    </div>
  );
};

export default About;

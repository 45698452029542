import PricingCard from "../Card/Card";
import { masteringPriceData, mixingPriceData, studioRecordingPriceData } from "../MockData/Mock";

const Pricing = () => {
  // const [pricing, setPricing] = useState(true);

  // const handlePrcingTag = () => {
  //   setPricing(!pricing);
  // };

  return (
    <div className=" lg:my-[6rem]" id="pricing">
      <div>
        <h3 className="text-3xl text-center leading-relaxed tracking-wider">
          Solo, Agency or Team? We’ve got you{" "}
          <span className="underline underline-offset-4">covered.</span>
        </h3>
      </div>

      <div className="flex justify-center my-[3rem] " >
    <h4 className="text-[#F9CD51] underline-offset-8 underline text-3xl ">Pricing</h4>
      </div>

      <div className=" lg:grid  lg:grid-cols-9 gap-10">
   
          <>
            <PricingCard
              amount="90"
              subTitle="with unlimited email"
              subscriptionType="Mixing"
              title="Per editor, yearly"
              backGround="bg-[#F9CD51] "
              pricingArray={mixingPriceData}
            />
            <PricingCard
              amount="125"
              subTitle="For team with 8 users"
              subscriptionType="Mastering"
              title="For team, yearly"
              backGround="bg-[#F9CD51] "
              pricingArray={masteringPriceData}
            />

            <PricingCard
              amount="370"
              subTitle="for unlimited users"
              subscriptionType="Recording studio"
              title=""
              backGround="bg-[#F9CD51] "
              pricingArray={studioRecordingPriceData}
            />
          </>
     
      </div>
    </div>
  );
};

export default Pricing;
